<template>
  <div>
    <v-container >
      <v-row no-gutters>
        <v-col cols="12" class="text-right hidden-print-only">
            <v-btn depressed color="primary" class="mt-1 mr-5" @click="printVoucher">
          <v-icon>print</v-icon>
        </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-row no-gutters>
        <v-col cols="12" md="12" >
          <v-row no-gutters>
             <v-col cols="2">
              <img :src="file" ref="cropper" style="height: 13vh;" />
            </v-col>
            <v-col cols="8" class="text-center pt-2">
              <v-row no-gutters>
                <v-col cols="12" class="display-2 first-heading-font">{{company.companyName}}</v-col>
                <v-col cols="12" class="headline font-weight-bold">
                  <span class="heading-font ">TRAVELS & TOURS</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="vertical-divider"></div>
            </v-col>
            <v-col cols="12" v-if="invoiceDto.type=='INVOICE'" class="text-center font-weight-bold title">"Invoice"</v-col>
            <v-col cols="12" v-else class="text-center font-weight-bold title">"Expense"</v-col>
            
            <v-col cols="6" md="6">
                {{  invoiceDto.customer.customerName  }}
            </v-col>
            <v-col cols="6" md="6" class="text-right">
                Date : {{ invoiceDto.lastPaymentDate  }}
            </v-col>
            <v-col cols="6" md="6">
               Tel :{{ invoiceDto.customer.phone }}
            </v-col>
            <v-col cols="6" md="6" class="text-right">
                Vr No. : {{ invoiceDto.voucherCode }}
            </v-col>
            <v-col cols="12" md="12" class="text-left">
                Address : {{ invoiceDto.customer.address }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" class="pt-1">
                <v-row  class="primary white--text" no-gutters>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="1" md="1"  class="text-center">No.</v-col>                  
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <!-- <v-col cols="1" md="1"  class="text-center">Invoice No.</v-col>    
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col> -->
                  <v-col class="pl-2 text-center">Particular</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="1" md="1"   class="text-center">Qty</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2"  class="text-right pr-2">Price</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2"   class="text-right pr-2">Amount</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                   <v-col cols="1" md="1"  class="text-center">1</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <!-- <v-col  cols="1" md="1"  class="text-center">{{invoiceDto.invPayment[0].invoiceCode}}</v-col>
                   <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col> -->
                  <v-col class="pl-2" >
                  <span v-if="invoiceDto.type=='INVOICE'"> Invoice ({{invoiceDto.invPayment[0].invoiceCode}})</span>
                <span v-else>Expense ({{invoiceDto.invPayment[0].invoiceCode}})</span>
                </v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="1" md="1"  class="text-center pr-2">1</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2"  class="text-right pr-2">{{invoiceDto.totalAmount|numberFormat}}</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2" class="text-right pr-2">{{invoiceDto.totalAmount|numberFormat}}</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="12" md="12"><v-divider ></v-divider></v-col>
                </v-row>
                <v-row v-for="(tempVoucher,index) in invoiceDto.invPayment" :key="index" no-gutters>
                  <v-col>
                    <v-row v-if="invoiceDto.paymentTerm!=tempVoucher.paymentTerm"  no-gutters>                      
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                   <v-col cols="1" md="1"  class="text-center">{{index+2}}</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <!-- <v-col  cols="1" md="1"  class="text-center">{{tempVoucher.invoiceCode}}</v-col>
                   <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col> -->
                  <v-col class="pl-2" >
                  <span> {{tempVoucher.paymentTerm }} ({{tempVoucher.invoiceCode}})</span> - on "
                  <span >{{tempVoucher.date}}</span> "
                  <span>{{tempVoucher.detail}}</span></v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="1" md="1"  class="text-center pr-2">1</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2"  class="text-right pr-2">{{tempVoucher.payment|numberFormat}}</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2" class="text-right pr-2">({{tempVoucher.payment|numberFormat}})</v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="12" md="12"><v-divider ></v-divider></v-col>                  
                </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col class="pl-2">
                    Remark : {{ invoiceDto.remark }}
                  </v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2" >
                    <v-row no-gutters>
                      <v-col cols="12" md="12" class="text-right pr-2">Total</v-col>
                      <v-col cols="12" md="12"><v-divider></v-divider></v-col>
                      <v-col cols="12" md="12" v-if="invoiceDto.type=='INVOICE'" class="text-right pr-2">Received</v-col>
                      <v-col cols="12" md="12" v-if="invoiceDto.type=='EXPENSE'" class="text-right pr-2">Paid</v-col>
                      <v-col cols="12" md="12"><v-divider></v-divider></v-col>
                      <v-col cols="12" md="12" class="text-right pr-2">Balance</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="2" md="2">
                    <v-row no-gutters>
                      <v-col cols="12" md="12" class="text-right pr-2">{{invoiceDto.totalAmount-invoiceDto.advancePaid+invoiceDto.lastPaymentAmt|numberFormat}}</v-col>
                      <v-col cols="12" md="12"><v-divider></v-divider></v-col>
                      <v-col cols="12" md="12" class="text-right pr-2">{{invoiceDto.lastPaymentAmt|numberFormat}}</v-col>
                      <v-col cols="12" md="12"><v-divider></v-divider></v-col>
                      <v-col cols="12" md="12" class="text-right pr-2">{{invoiceDto.totalAmount-invoiceDto.advancePaid|numberFormat}}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="0" md="0"><v-divider vertical></v-divider></v-col>
                  <v-col cols="12" md="12"><v-divider ></v-divider></v-col>
                </v-row>
                </v-col>
                <v-col cols="12" md="12" class="mt-4">
                <footer>
                  <v-row no-gutters >
                  <v-col cols="12" md="12" v-if="invoiceDto.type=='INVOICE'"  class="text-center">
                    <span>This is computer generated invoice No Signature required.</span>
                  </v-col>
                  <v-col cols="12" md="12" v-if="invoiceDto.type=='EXPENSE'"  class="text-center">
                    <span>This is computer generated payment No Signature is required.</span>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" md="12" class="mt-5 mb-5"><v-divider></v-divider></v-col>
                  <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="4" class="caption pl-4 text-center" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="4" class="text-center caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
            
          </v-col>
                </v-row>
              </footer>
                </v-col>
                <!-- <v-col cols="12" md="12" class="mt-10">
                <v-row no-gutters >
                  <v-col cols="6" md="6" class="text-center">
                    <span>Customer's Signature/Name</span>
                  </v-col>
                  <v-col cols="6" md="6" class="text-center">
                    <span>Official's Signature/Name</span>
                  </v-col>
                </v-row>
              </v-col> -->
              <!-- <v-col cols="12" md="12" class="mt-10">
                <v-row no-gutters>
                  <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="4" class="caption pl-4 text-center" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="4" class="text-center caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
            
          </v-col>
                </v-row>
                </v-col> -->
              </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
 import invoiceService from "../service/InvoiceService";
 import companyInfoService from "../service/CompanyInfoService";
export default {
  data: () => ({
    company: {},
    file: "",
    invoiceDto:{},
  }),
  props: {},
  mounted: function () {
    let invoiceId = this.$route.query.invoiceId;
    let invPyamentId=this.$route.query.invPId;
    if (invoiceId != undefined) {
      this.getInvoiceDetail(invoiceId,invPyamentId);
      this.getCompanyInfo();
    }
  },
  methods: {
    getInvoiceDetail:function(invoiceId,invPyamentId){
      invoiceService
          .getInvPaymentById(invoiceId,invPyamentId)
          .then((response) => {
            this.invoiceDto = response;  
            console.log(response);
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
    },
    getCompanyInfo: function() {
      companyInfoService.getCompanyInfo().then(response => {
        this.company = Object.assign({}, response);
        //this.file = `${axios.defaults.baseURL}/companyLogo/${response.logo}`;
      });
    },
    printVoucher: function () {
      window.print();
    },
  },
  watch: {},
  components: {},
};
</script>
<style scoped>
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding: 0px;
}
.v-data-table td,
.v-data-table th {
  padding: 0 2px !important;
}
.fullscreen {
  height: 100vh !important;
  overflow-x: scroll;
}

@font-face {
  font-family: "Handage";
  src: url("/font/HANDA__.woff") format("woff");
  src: url("/font/HANDA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haettenschweiler";
  src: url("/font/Haettenschweiler.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.heading-font {
  font-family: "Handage";
  letter-spacing: 0.3em;
}
.first-heading-font {
  font-family: "Haettenschweiler" !important;
  font-weight: 300;
  letter-spacing: 0.1em !important;
}
.vertical-divider {
  border-top: 2px solid black;
}
.rotateToLeft {
  margin: 100px 0 0 0px; /*Changed*/
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.v-application p {
    margin-bottom: 0px !important;
}
.a {
  text-decoration: none;
}

@page {
    size: letter;
    margin: .5in;
}

@media print {
    table.paging thead td, table.paging tfoot td {
        height: 1.2in;
    }
}

header, footer {
    width: 100%; height: 1.2in;
}

header {
    position: absolute;
    top: 0;
}

@media print {
    header, footer {
        position: fixed;
    }
    
    footer {
        bottom: 0;
    }
}
</style>
